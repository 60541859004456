import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUnlockAlt} from '@fortawesome/free-solid-svg-icons';
import {observer} from 'mobx-react-lite';
import Modal from '../../shared/modal/Modal';
import {useStore} from '../../../hooks/useStore';
import LoginForm from '../../login/LoginForm';
import {useMQTTAuthSubscription} from '../../../hooks/useMQTT';
import {Api} from '../../../middleware/api';
import {OverruleType} from './InspectionTaskExecutionForm';

const SecondUserLoginModal = ({
  open = true,
  requiredRoleId,
  onCancel,
  onUserAllowed,
  title,
  modalType,
  ...props
}) => {
  const store = useStore();

  const onPermissionCheckCompleted = ({success, message, userId}) => {
    if (success) {
      onUserAllowed(userId);
    } else {
      store.flashMessageStore.addFlashMessage({type: 'error', title: message});
    }
  };

  const handleSubmit = (loginCredentials, overwriteAuthentication) => {
    store.authStore
      .checkRequiredRolePermissions({
        requiredRoleId,
        authenticate: () => (overwriteAuthentication && overwriteAuthentication())
          || Api.auth.login(loginCredentials).then(({data}) => data),
        onCheckCompleted: onPermissionCheckCompleted,
        isOverrule: modalType === OverruleType.OVERRULE,
      })
      .catch((error) => {
        store.flashMessageStore.addFlashMessage({type: 'error', title: error.message || 'Error'});
      });
    store.authStore.setDeactivateRootTerminalLogin(false);
  };

  useMQTTAuthSubscription({
    store,
    visible: open,
    onAuthentication: (loginResponse) => {
      handleSubmit({username: loginResponse?.user?.username}, () => loginResponse);
    },
  });

  return (
    <Modal
      title={(
        <>
          <FontAwesomeIcon icon={faUnlockAlt}/>
          {' '}
          {title}
        </>
      )}
      open={open}
      footer={false}
      onCancel={onCancel}
      {...props}
    >
      <LoginForm onSubmit={handleSubmit}/>
    </Modal>
  );
};

export default observer(SecondUserLoginModal);
