import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Modal from '../shared/modal/Modal';
import Button from '../shared/buttons/Button';
import { useStore } from '../../hooks/useStore';
import { useMQTTAuthSubscription } from '../../hooks/useMQTT';

const SilentLogin: React.FC = () => {
  const store = useStore();
  const [pendingLogin, setPendingLogin] = useState(undefined);
  const { t } = useTranslation();

  useMQTTAuthSubscription({
    store,
    visible: true,
    onAuthentication: (loginResponse: any) => {
      if (store.authStore.user) {
        if (!store.authStore.deactivateRootTerminalLogin) {
          setPendingLogin(loginResponse);
        }
      } else {
        store.authStore.terminalLogin(loginResponse);
        window.location.reload();
      }
    },
  });

  const handleConfirmSwitch = () => {
    if (pendingLogin) {
      store.authStore.logout(true);
      store.authStore.terminalLogin(pendingLogin);
      setPendingLogin(undefined);
      window.location.reload();
    }
  };

  const handleCancelSwitch = () => {
    setPendingLogin(undefined);
  };

  return (
    <Modal
      open={pendingLogin}
      title={t('silentLogin.title')}
      onCancel={handleCancelSwitch}
      footer={[
        <Button key="back" onClick={handleCancelSwitch}>
          {t('links.cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={handleConfirmSwitch}>
          {t('links.confirm')}
        </Button>,
      ]}
    >
      <p>{t('silentLogin.message')}</p>
    </Modal>
  );
};

export default observer(SilentLogin);
